import ReactGA from 'react-ga';
import ReactGA4 from 'react-ga4';
import {
    isServer,
} from 'shared/utils'

let GA_TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID
let GA4_TRACKING_ID = process.env.REACT_APP_GA4_TRACKING_ID

export const setTrackingId = () => {
    if (GA_TRACKING_ID) {
        console.log('Set GA Tracking ID', GA_TRACKING_ID)
        ReactGA.initialize(GA_TRACKING_ID);
        ReactGA.set({ anonymizeIp: true });
        ReactGA.pageview(window.location.pathname + window.location.search);
    }
    if (GA4_TRACKING_ID) {
        console.log('Set GA4 Tracking ID', GA4_TRACKING_ID)
        ReactGA4.initialize(GA4_TRACKING_ID);
        ReactGA4.set({ anonymizeIp: true });
        // ReactGA4.pageview(window.location.pathname + window.location.search);
        ReactGA4.send('pageview', { page: window.location.pathname + window.location.search });
    }
}

if ((GA_TRACKING_ID || GA4_TRACKING_ID) && !isServer()) {
    setTrackingId()
}

export const event = ev => {
    if (!GA_TRACKING_ID && !GA4_TRACKING_ID) {
        console.log('Ignore analytics event on dev', ev)
        return
    }
    if (GA_TRACKING_ID) {
        ReactGA.event(ev)
    }
    if (GA4_TRACKING_ID) {
        ReactGA4.event(ev)
    }
}
export const pageview = path => {
    if (!GA_TRACKING_ID && !GA4_TRACKING_ID) {
        console.log('Ignore analytics pageview on dev', path)
        return
    }
    if (GA_TRACKING_ID) {
        ReactGA.pageview(path)
    }
    if (GA4_TRACKING_ID) {
        // ReactGA4.pageview(path)
        ReactGA4.send('pageview', { page: path });
    }
}